<template>
  <b-row>
    <b-col cols="12">
      <field ref="fields" :disabled="disabled" :entity="entity" :table-definition="tableDefinition"
             :field="customergroupField"/>
    </b-col>
    <b-col cols="12">
      <field ref="fields" :disabled="disabled" :entity="entity" :table-definition="tableDefinition"
             :field="companyField"/>
    </b-col>
    <b-col cols="12">
      <field ref="fields" :filter-value="entity.company_id" :disabled="disabled || entity.company_id==null"
             :entity="entity" :table-definition="tableDefinition" :field="getField('pos_id')"/>
    </b-col>
    <b-col cols="12">
      <field ref="fields" :filter-value="entity.pos_id" :disabled="disabled || entity.pos_id==null"
             :entity="entity" :table-definition="tableDefinition" :field="getField('contract_id')"/>
    </b-col>
    <b-col
      v-for="(field,i) in ['ticket_name','ticket_description','priority_id','ticket_priority_deadline_value']"
      :key="i" cols="12">
      <field v-if="isFieldVisible(field)" :disabled="disabled" :entity="entity" :table-definition="tableDefinition" :field="getField(field)"/>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import Field from '@/views/app/Generic/Field'

export default {
  name: 'ContactPersonForm',
  components: {
    Field,
    BRow,
    BCol,
  },
  props: ['disabled', 'entity', 'tableDefinition', 'definition', 'create'],
  data() {
    return {
      companyField: {
        key: 'company_id',
        type: 'list',
        list: 'company',
        listLabel: 'company_name',
        required: false,
        relationEntity: false,
        filter_key: 'customergroup_id',
      },
      customergroupField: {
        key: 'customergroup_id',
        type: 'list',
        list: 'customergroup',
        listLabel: 'customergroup_name',
      },
    }
  },
  methods: {
    getField(key) {
      return this.definition.fields.find(f => f.key === key)
    },
    isFieldVisible(key) {
      const field = this.getField(key)

      if (field.hideOnForm) return false
      if (this.create && field.hideOnCreate) return false
      if (!this.create && field.hideOnUpdate) return false

      return true
    },
  },

}
</script>
